<template>
  <div class="home mt-5">
    <div class="container-fluid">
      <div class="row my-3 justify-content-center">
        <div class="col-12 col-lg-10 row">
          <div class="col-12">
            <div class="h3">Der Weg zum Führerschein</div>
            <div class="h1 company-color1">Leitfaden</div>
          </div>
          <div class="col-12">
            <p>
              Hier haben wir dir einen Leitfaden zusammengestellt, wie du deinen Führerschein erwerben kannst.
              <br />Komm am Besten trotzdem in eine unserer Filialen. Wir beraten dich gern!
            </p>
            <small class="text-muted">- Dein Team der Fahrschule Werner</small>
          </div>
        </div>
        <div class="col-12">
          <div class="my-5"></div>
          <!-- Platzhalter -->
        </div>
        <div class="col-12 col-lg-10">
          <div class="row align-items-center my-5">
            <div class="col-12 col-sm-6 text-center my-5">
              <div class="text-uppercase font-weight-bold">Du kannst sofort starten!</div>
              <div class="row justify-content-center">
                <div class="col-5">
                  <hr />
                </div>
              </div>
              <div class="text-uppercase text-muted">Schritt 1</div>
            </div>
            <div class="col-12 col-sm-6 opacity-90">
              <p>
                Nachdem du einen Ausbildungsvertrag mit uns geschlossen hast kannst du sofort mit der Theorie loslegen.
                <br />Komm dazu am Besten in eine unserer Filialen. Wenn du noch Fragen hast, dich unsicher fühlst oder nicht weißt wie du deinen Führerschein finanzieren kannst, sprich uns an!
                <br />Wir beraten dich gerne und finden eine Lösung.
              </p>Bitte kümmere dich darum, dass du ein aktuelles
              <strong>Passfoto</strong>, einen
              <strong>Sehtest</strong>, der nicht älter als 2 Jahre ist und einen
              <strong>Erste-Hilfe-Kurs</strong>, welcher ebenfalls nicht älter als 2 Jahre ist, gemacht hast.
              <br />Kleiner Tipp: Unterschätze das Lernen für die Theorieprüfung nicht!
            </div>
          </div>
          <br />
          <div class="row align-items-center my-5">
            <div class="col-12 col-sm-6 text-center my-5">
              <div class="text-uppercase font-weight-bold">Der Weg zur ersten Fahrstunde</div>
              <div class="row justify-content-center">
                <div class="col-5">
                  <hr />
                </div>
              </div>
              <div class="text-uppercase text-muted">Schritt 2</div>
            </div>
            <div class="col-12 col-sm-6 opacity-90">
              Damit du deine erste Fahrstunde und alle weiteren Prüfungen absolvieren kannst, muss ein sogenannter Prüfauftrag vorliegen.
              <br />Alle notwendigen Unterlagen gibst du bei deiner zuständigen Führerscheinstelle ab:
              <br />
              <div class="col-12">
                <!-- margin -->
                <ul>
                  <li>Führerscheinantrag</li>
                  <li>Bescheinigung Erste-Hilfe-Kurs (nicht älter als 2 Jahre)</li>
                  <li>Sehtest (nicht älter als 2 Jahre)</li>
                  <li>Passfoto</li>
                  <li>ggf. Zusatzantrag für Führerschein mit 17 Jahren</li>
                </ul>
              </div>
              <p>
                Weitere Informationen erhälst du auf der
                <a
                  href="https://www.leipzig.de/buergerservice-und-verwaltung/aemter-und-behoerdengaenge/aemtertermine-online/terminvereinbarung-fahrerlaubnisbehoerde/"
                  target="_blank"
                >Webseite</a> der Stadt Leipzig bzw. deiner Fahrerlaubnisbehörde.
                <br />Hier findest du auch Sonderregelungen für Berufskraftfahrer.
                Übrigens: Du kannst dir auch
                <a
                  href="https://www.leipzig.de/fachanwendungen/termine/index-fahrerlaubnis.html"
                  target="_blank"
                >Online einen Termin ausmachen</a>.
              </p>Die Fahrschule erhält in der Regel nach ca. 2-4 Werktagen den Prüfauftrag.
              <br />
              <br />
              <small>
                Falls du zu einer Fahrstunde nicht erscheinen kannst, sage diese bitte mind.
                <strong>48h im Vorraus</strong> ab. Ansonsten müssen wir dir die Fahrstunde dennoch in Rechnung stellen.
              </small>
            </div>
          </div>
          <br />
          <div class="row align-items-center my-5">
            <div class="col-12 col-sm-6 text-center my-5">
              <div class="text-uppercase font-weight-bold">Die Theoretische Prüfung</div>
              <div class="row justify-content-center">
                <div class="col-5">
                  <hr />
                </div>
              </div>
              <div class="text-uppercase text-muted">Schritt 3</div>
            </div>
            <div class="col-12 col-sm-6 opacity-90">
              <p>
                Wenn du deine Theorieprüfung absolvieren möchtest, kommst du am Besten mindestens zwei Wochen vor deinem Wunschtermin zu uns. Wenn du prüfungsbereit bist, erhälst du von uns eine Ausbildungsbescheinigung, die du dann am Prüfungstag dem Prüfer vorlegen musst.
                <br />Vergiss nicht auch deinen
                <strong>Personalausweis</strong> oder
                <strong>Reisepass</strong> mitzubringen und achte sorgfältig auf deine
                <strong>Ausbildungsbescheinigung</strong>!
              </p>
              <p>
                Die theoretische Prüfung findet bei der DEKRA Automobil GmbH Niederlassung Leipzig, Torgauer Str. 235, 04347 Leipzig statt. Weitere Informationen erhälst du auf deren
                <a
                  href="https://www.dekra.de/leipzig/automobil/fahrerlaubnis-fahreignung/fuehrerschein/theoretische-praktische-fahrerlaubsnispruefung-leipzig.html"
                  target="_blank"
                >Webseite</a>. (aktuelle Prüftermine)
              </p>
              <p>
                Solltest du zum Zeitpunkt der Anmeldung deine Pflichtstunden noch nicht absolviert haben, erhälst du die Bescheinigung nach der letzten Theoriestunde.
                <br />Die Prüfgebühr bezahlst du bei uns in der Fahrschule.
              </p>Für deine Prüfung kannst du mit der App
              <a href="https://www.click-learn.de/" target="_blank">Online 360°</a> üben. Deinen Zugangscode für die App bekommst du von uns, sobald du mit deiner Ausbildung begonnen hast. Du hast außerdem bei uns die Möglichkeit, das Fahren mit unserem Fahrsimulator zu üben.
              <br />Deine Ergebnisse können wir ebenfalls sehen, so dass wir gemeinsam bestimmen können, wann du für die Prüfung bereit bist.
            </div>
          </div>
          <br />
          <div class="row align-items-center my-5">
            <div class="col-12 col-sm-6 text-center my-5">
              <div class="text-uppercase font-weight-bold">Die Praktische Prüfung</div>
              <div class="row justify-content-center">
                <div class="col-5">
                  <hr />
                </div>
              </div>
              <div class="text-uppercase text-muted">Schritt 4</div>
            </div>
            <div class="col-12 col-sm-6 opacity-90">
              <p>
                Wenn du die Theorieprüfung bestanden, alle Pflichtstunden gefahren und dein Fahrlehrer und du denken, dass du bereit für die Prüfung bist, vereinbaren wir einen Termin für die Praktische Prüfung. Einen Wunschtermin gibt es anders, als bei der theoretischen Prüfung, jedoch nur eingeschränkt.
                <br />Falls du für diesen Tag eine Entschuldigung oder Bestätigung für die Schule/deinen Arbeitgeber benötigst, teile uns dies bitte rechtzeitig mit.
              </p>
              <p>
                <strong>Auch für die praktische Prüfung gilt: Wer unentschuldigt, d.h. ohne ärztlichen Attest fehlt, muss die Prüfgebühr dennoch entrichten.</strong>
              </p>
            </div>
          </div>
          <div class="row my-5">
            <div class="col-12 text-center">
              <div class="row justify-content-center">
                <div class="col-5">
                  <hr />
                </div>
              </div>
              <div class="text-uppercase font-weight-bold">Ziel erreicht</div>
              <div class="text-muted">Wir wünschen dir viel Erfolg mit deinem Führerschein</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide"
};
</script>
